function Three() {
  return (
    <div>
      <h1>3</h1>
      <p>This is page 3</p>
    </div>
  );
}

export default Three;
