function Two() {
  return (
    <div>
      <h1>2</h1>
      <p>This is page 2</p>
    </div>
  );
}

export default Two;
