function One() {
  return (
    <div>
      <h1>1</h1>
      <p>This is page 1</p>
    </div>
  );
}

export default One;
